@font-face {
  font-family: 'Gotham Rounded Medium';
  font-display: swap;
  src: url('./src/assets/Gotham-Rounded-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham Rounded Bold';
  font-display: swap;
  src: url('./src/assets/Gotham-Rounded-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham Rounded Book';
  font-display: swap;
  src: url('./src/assets/GothamRnd-Book.otf') format('opentype');
}

body {
  background-color: transparent !important;
}

h2 {
  color: #1f3664 !important;
  font-weight: normal !important;
  font-size: 32px !important;
  font-family: Gotham Rounded Bold;

  line-height: 1.1em !important;
  font-weight: 100 !important;
  margin: 10px auto 15px !important;
  text-align: center;
}
/*Forms  */
form {
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  flex: 1;
}
.form-label {
  display: none;
}

.form-control {
  height: 42px;
  padding-left: 15px !important;
  border-radius: 20px !important;

  font-size: 13px !important;
  color: #666 !important;
  background-color: #fff;
  width: 100%;
  font-weight: 400;

  font-family: 'Gotham Rounded Book', Helvetica, Arial, Lucida, sans-serif !important;
  line-height: 33px;
}

label.form-check-label {
  font-weight: bold;

  font-size: 13px !important;
  color: #666 !important;
  width: 100%;
  font-weight: 400;

  font-family: 'Gotham Rounded Book', Helvetica, Arial, Lucida, sans-serif !important;
}

.form-check-input:checked {
  background-color: #234197 !important;
  border-color: #234197 !important;
  border: 2px solid white !important;
  outline: 1px solid #234197 !important;
}
.form-check-input:checked[type='radio'] {
  --bs-form-check-bg-image: unset !important;
}

.form-text {
  font-size: 0.7em !important;
}

.options {
  margin-bottom: 15px;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1f3664;
  --bs-btn-border-color: #1f3664;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-border-color: #1f3664;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1f3664;
  --bs-btn-active-border-color: #1f3664;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1f3664;
  --bs-btn-disabled-border-color: #1f3664;
}

.chart {
  left: 0;
  opacity: 0.6;
  height: 400px;
  aspect-ratio: auto;
}

.baby,
.debug-dot {
  width: 20px;
  height: 20px;
  position: absolute;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.baby {
  background-color: orange;
  border: 2px #fff solid;
  border-radius: 50%;
  z-index: 1;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

button.btn-primary {
  margin-top: 30px;
  color: #ffffff !important;
  border-width: 0px !important;
  border-radius: 50px;
  font-size: 15px !important;
  font-family: 'Gotham Rounded Bold', Helvetica, Arial, Lucida, sans-serif !important;
  background-color: #2b3355;
  padding-top: 10px !important;
  font-size: 12px !important;
  line-height: 19.62px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 15px 35px !important;
  min-width: 150px;
  margin: 30px auto;
}

button.btn-primary:hover {
  color: #fff;
  background: #7dc9de;
  border-color: #7dc9de;
}

span {
  font-family: 'Gotham Rounded Medium', Helvetica, Arial, Lucida, sans-serif;
  color: #1f3664;
  font-size: 16px;
  line-height: 1.3em;
  letter-spacing: 0px;
  text-transform: none;
  font-weight: normal;
  display: block;
  text-align: center;
}

span.description {
  font-weight: 500;
  font-size: 18px !important;
  line-height: 21px !important;

  color: #666 !important;
  font-family: 'Gotham Rounded Book', Helvetica, Arial, Lucida, sans-serif !important;
  margin-bottom: 40px !important;
}

.charts h2 {
  margin-bottom: 5px !important;
}
.charts .block {
  padding-left: 10px;
  padding-bottom: 20px;
}

div.options.row {
  margin-top: 35px;
  margin-bottom: 20px;
}

.chart-weight,
.chart-height {
  display: flex;
  justify-content: center;
}

.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 130px;
  gap: 20px;
}
.toolbar .col:first-child button {
  float: right;
}
